<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<template>
  <ul :class="$style.breadcrumb">
    <li class="breadcrumb-top">
      <router-link to="/">HOME</router-link>
    </li>
    <slot />
  </ul>
</template>

<style lang="scss" module>
.breadcrumb {
  padding: 0;
  margin: 8px 0;
  font-size: 12px;

  &-top {
    text-transform: uppercase;
  }

  li {
    display: inline-block;
    color: #aaa;

    &:not(:last-child)::after {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 8px;
      color: #888;
      content: '';
      border-top: solid 2px;
      border-right: solid 2px;
      transform: rotate(45deg);
    }
  }
}
</style>
