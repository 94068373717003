<script lang="ts">
import { IUser } from '@/models/user';

export default {
  props: {
    user: {
      type: Object as () => IUser,
      required: true,
    },
  },
};
</script>

<template>
  <div :class="$style.component">
    <p :class="$style.author">
      AUTHOR：<router-link :to="`/author/${user.user_login}`" :title="`${user.display_name}の投稿`" rel="author">{{
        user.display_name
      }}</router-link>
    </p>
    <img v-if="user.avatar" :class="$style.avatar" :src="user.avatar" :alt="user.display_name" />
    <img v-else :class="$style.avatar" src="@/assets/img/user.png" :alt="user.display_name" />
    <p :class="$style.description">
      {{ user.description }}
    </p>
    <p v-if="user.user_url" :class="$style.website">
      <a :href="user.user_url" target="_blank">WEBSITE</a>
    </p>
  </div>
</template>

<style lang="scss" module>
.component {
  overflow: hidden;

  p {
    margin: 0;
    padding: 0;
  }

  .avatar {
    display: block;
    border-radius: 50%;
    float: left;
    width: 80px;
    height: 80px;
    margin-right: 8px;
  }

  .description {
    font-size: 12px;
    line-height: 1.4;
    color: $color-text-grey;
  }

  .website {
    font-size: 12px;
    font-weight: bold;

    a {
      color: $color-twitter;
    }
  }
}
</style>
