
import { IComment } from '@/models/comment';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    comments: {
      type: Object as () => IComment[],
      required: true,
    },
  },
  setup: (props, context) => {
    const replyTrigger = (comment: IComment) => {
      context.emit('reply', comment);
    };

    return {
      replyTrigger,
    };
  },
});
