
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import NotFound from '@/components/pages/404.vue';
import Author from '@/components/molecules/Author.vue';
import Spinner from '@/components/atoms/Spinner.vue';
import Figure from '@/components/molecules/Figure.vue';
import Comment from '@/components/molecules/Comment.vue';
import CommentForm from '@/components/molecules/CommentForm.vue';
import Breadcrumb from '@/components/molecules/Breadcrumb.vue';
import { IComment } from '@/models/comment';

export default defineComponent({
  components: {
    NotFound,
    Author,
    Spinner,
    Figure,
    Comment,
    CommentForm,
    Breadcrumb,
  },
  emits: ['loaded'],
  setup: (props, context) => {
    const state = computed(() => store.post.state);
    const post = computed(() => store.post.state.get);
    const postLoading = computed(() => store.post.state.loading);
    const user = computed(() => store.user.state.get);
    const userLoading = computed(() => store.user.state.loading);
    const relation = computed(() => store.relation.state.index);
    const relationLoading = ref(true);
    const comments = computed(() => store.comment.state.index);
    const $route = useRoute();
    const $router = useRouter();
    const slug = computed(() => $route.params.slug);
    const title = computed(() => store.option.state.get.option_value);
    const replySourceComment: IComment = reactive({});

    onMounted(async () => {
      if (slug.value instanceof Array) {
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const postId = Number(params.get('p') || params.get('preview_id') || 0);
      const isPreview = Boolean(params.get('preview')) || false;

      await store.auth.init();

      if (isPreview && !store.auth.state.me.ID) {
        $router.push('/login');
      }

      await Promise.all([
        store.option.title(),
        isPreview ? store.post.preview(postId) : store.post.get(slug.value),
        store.comment.index(slug.value),
      ]);

      const postTitle = post.value.post_title;
      document.title = `${postTitle}｜${title.value}`;
      const $meta =
        document.querySelector<HTMLMetaElement>('meta[name="description"]') || document.createElement('meta');
      $meta.setAttribute('name', 'description');
      $meta.setAttribute('content', post.value.post_excerpt || '');
      const head = document.querySelector('head');
      head?.appendChild($meta);

      if (store.post.state.get.post_type === 'post') {
        store.post.clickCounter(slug.value);
      }

      loaded();

      const tagIds = store.post.state.get.tags?.map(tag => tag.term_id as number) || [];
      await Promise.all([
        store.user.get(store.post.state.get?.post_author || 0),
        store.relation.index(slug.value, tagIds, 5),
      ]);
      relationLoading.value = false;
    });

    const loaded = () => {
      context.emit('loaded');
    };

    const replyClicked = (sourceComment: IComment) => {
      replySourceComment.comment_ID = sourceComment.comment_ID;
      replySourceComment.comment_post_ID = sourceComment.comment_post_ID;
      replySourceComment.comment_author = sourceComment.comment_author;
      replySourceComment.comment_author_email = sourceComment.comment_author_email;
      replySourceComment.comment_author_url = sourceComment.comment_author_url;
      replySourceComment.comment_date = sourceComment.comment_date;
      replySourceComment.comment_content = sourceComment.comment_content;
      replySourceComment.comment_approved = sourceComment.comment_approved;
      replySourceComment.comment_agent = sourceComment.comment_agent;
      replySourceComment.comment_type = sourceComment.comment_type;
      replySourceComment.comment_parent = sourceComment.comment_parent;
      replySourceComment.avatar = sourceComment.avatar;
      replySourceComment.replies = sourceComment.replies;
      replySourceComment.user_id = sourceComment.user_id;
    };

    const replyCancelClicked = () => {
      replySourceComment.comment_ID = 0;
      replySourceComment.comment_post_ID = 0;
      replySourceComment.comment_author = '';
      replySourceComment.comment_author_email = '';
      replySourceComment.comment_author_url = '';
      replySourceComment.comment_date = '';
      replySourceComment.comment_content = '';
      replySourceComment.comment_approved = '';
      replySourceComment.comment_agent = '';
      replySourceComment.comment_type = '';
      replySourceComment.comment_parent = 0;
      replySourceComment.avatar = '';
      replySourceComment.replies = [];
      replySourceComment.user_id = 0;
    };

    return {
      state,
      post,
      postLoading,
      user,
      userLoading,
      loaded,
      relation,
      relationLoading,
      comments,
      replyClicked,
      replySourceComment,
      replyCancelClicked,
    };
  },
});
