
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import store from '@/store';
import { ICommentPostRequest, IComment } from '@/models/comment';
import { IPost } from '@/models/post';
import { useRouter } from 'vue-router';
import { Error } from '@/models/error';
import Spinner from '@/components/atoms/Spinner.vue';

export default defineComponent({
  props: {
    post: {
      type: Object as () => IPost,
      required: true,
    },
    replySourceComment: {
      type: Object as () => IComment,
      required: true,
    },
  },
  components: {
    Spinner,
  },
  setup: props => {
    const request: ICommentPostRequest = reactive({ author: '', content: '', parent: 0, user_id: 0, email: '' });
    const error = ref('');
    const sending = ref(false);
    const $router = useRouter();
    const loginUser = computed(() => store.auth.state.me);

    watch(props.replySourceComment, () => {
      request.parent = props.replySourceComment.comment_ID || 0;
    });

    onMounted(async () => {
      const authUserId = store.auth.state.id;
      if (authUserId) {
        await store.auth.me(authUserId);
        request.user_id = store.auth.state.me.ID;
        request.author = store.auth.state.me.display_name;
        request.email = store.auth.state.me.user_email;
      }
      return;
    });

    const sendClicked = async () => {
      if (request.content === '') {
        error.value = 'コメントを入力してください。';
        return;
      }

      sending.value = true;
      error.value = '';
      const slug = props.post.post_name || '';
      const response = await store.comment.post(slug, request);

      if (response.data instanceof Error) {
        error.value = 'コメント送信に失敗しました。';
        sending.value = false;
        return;
      }

      sending.value = false;
      $router.push('/thanks');
      return;
    };

    return {
      request,
      sendClicked,
      error,
      loginUser,
      sending,
    };
  },
});
