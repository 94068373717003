<script lang="ts">
import { IComment } from '@/models/comment';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    comments: {
      type: Object as () => IComment[],
      required: true,
    },
  },
  setup: (props, context) => {
    const replyTrigger = (comment: IComment) => {
      context.emit('reply', comment);
    };

    return {
      replyTrigger,
    };
  },
});
</script>

<template>
  <ul :class="$style.component">
    <li :class="$style.comment" v-for="(comment, i) in comments" :key="i">
      <div :class="$style.head">
        <img :class="$style.img" v-if="comment.avatar" :src="comment.avatar" alt="avatar" />
        <img v-else :class="$style.img" src="@/assets/img/user.png" alt="avatar" />
        <div :class="$style.text">
          <p :class="$style.author">{{ comment.comment_author || '匿名' }}</p>
          <p :class="$style.date">{{ comment.comment_date }}</p>
        </div>
      </div>
      <div>
        <p v-html="comment.comment_content"></p>
        <a href="#form" :class="$style.reply_button" @click="replyTrigger(comment)">返信</a>
        <ul :class="$style.replies">
          <li :class="$style.reply" v-for="(reply, i) in comment.replies" :key="i">
            <div :class="$style.head">
              <img v-if="reply.avatar" :class="$style.img" :src="reply.avatar" alt="avatar" />
              <img v-else :class="$style.img" src="@/assets/img/user.png" alt="avatar" />
              <div :class="$style.text">
                <p :class="$style.author">{{ reply.comment_author }}</p>
                <p :class="$style.date">{{ reply.comment_date }}</p>
              </div>
            </div>
            <p :class="$style.content" v-html="reply.comment_content"></p>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" module>
.component {
  list-style: none;
  padding: 0;

  .comment {
    &:not(:first-child) {
      border-top: solid 4px $color-background;
    }
  }

  .head {
    margin: 8px;
    display: flex;
    border-radius: 15px;

    .img {
      width: 32px;
      height: 32px;
      display: block;
      border-radius: 15px;
    }

    .text {
      display: flex;
      align-items: center;

      .author {
        font-style: italic;
        font-weight: 900;
        font-size: 13px;
        margin: 4px;
      }

      .date {
        font-size: 11px;
        color: #aaa;
        margin: 4px;
      }
    }
  }

  .reply_button {
    display: inline-block;
    margin: 8px 0;
    border-radius: 3px;
    font-size: 13px;
    color: #fff;
    background: #ccc;
    padding: 4px 8px;
    cursor: pointer;
  }

  .replies {
    padding: 0 8px;
    margin-left: 8px;
    list-style: none;
    border-left: solid 5px #eee;

    .reply {
      padding: 0 8px;
      box-sizing: border-box;
      border-top: solid 1px #eee;
    }
  }
}
</style>
