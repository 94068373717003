<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import NotFound from '@/components/pages/404.vue';
import Author from '@/components/molecules/Author.vue';
import Spinner from '@/components/atoms/Spinner.vue';
import Figure from '@/components/molecules/Figure.vue';
import Comment from '@/components/molecules/Comment.vue';
import CommentForm from '@/components/molecules/CommentForm.vue';
import Breadcrumb from '@/components/molecules/Breadcrumb.vue';
import { IComment } from '@/models/comment';

export default defineComponent({
  components: {
    NotFound,
    Author,
    Spinner,
    Figure,
    Comment,
    CommentForm,
    Breadcrumb,
  },
  emits: ['loaded'],
  setup: (props, context) => {
    const state = computed(() => store.post.state);
    const post = computed(() => store.post.state.get);
    const postLoading = computed(() => store.post.state.loading);
    const user = computed(() => store.user.state.get);
    const userLoading = computed(() => store.user.state.loading);
    const relation = computed(() => store.relation.state.index);
    const relationLoading = ref(true);
    const comments = computed(() => store.comment.state.index);
    const $route = useRoute();
    const $router = useRouter();
    const slug = computed(() => $route.params.slug);
    const title = computed(() => store.option.state.get.option_value);
    const replySourceComment: IComment = reactive({});

    onMounted(async () => {
      if (slug.value instanceof Array) {
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const postId = Number(params.get('p') || params.get('preview_id') || 0);
      const isPreview = Boolean(params.get('preview')) || false;

      await store.auth.init();

      if (isPreview && !store.auth.state.me.ID) {
        $router.push('/login');
      }

      await Promise.all([
        store.option.title(),
        isPreview ? store.post.preview(postId) : store.post.get(slug.value),
        store.comment.index(slug.value),
      ]);

      const postTitle = post.value.post_title;
      document.title = `${postTitle}｜${title.value}`;
      const $meta =
        document.querySelector<HTMLMetaElement>('meta[name="description"]') || document.createElement('meta');
      $meta.setAttribute('name', 'description');
      $meta.setAttribute('content', post.value.post_excerpt || '');
      const head = document.querySelector('head');
      head?.appendChild($meta);

      if (store.post.state.get.post_type === 'post') {
        store.post.clickCounter(slug.value);
      }

      loaded();

      const tagIds = store.post.state.get.tags?.map(tag => tag.term_id as number) || [];
      await Promise.all([
        store.user.get(store.post.state.get?.post_author || 0),
        store.relation.index(slug.value, tagIds, 5),
      ]);
      relationLoading.value = false;
    });

    const loaded = () => {
      context.emit('loaded');
    };

    const replyClicked = (sourceComment: IComment) => {
      replySourceComment.comment_ID = sourceComment.comment_ID;
      replySourceComment.comment_post_ID = sourceComment.comment_post_ID;
      replySourceComment.comment_author = sourceComment.comment_author;
      replySourceComment.comment_author_email = sourceComment.comment_author_email;
      replySourceComment.comment_author_url = sourceComment.comment_author_url;
      replySourceComment.comment_date = sourceComment.comment_date;
      replySourceComment.comment_content = sourceComment.comment_content;
      replySourceComment.comment_approved = sourceComment.comment_approved;
      replySourceComment.comment_agent = sourceComment.comment_agent;
      replySourceComment.comment_type = sourceComment.comment_type;
      replySourceComment.comment_parent = sourceComment.comment_parent;
      replySourceComment.avatar = sourceComment.avatar;
      replySourceComment.replies = sourceComment.replies;
      replySourceComment.user_id = sourceComment.user_id;
    };

    const replyCancelClicked = () => {
      replySourceComment.comment_ID = 0;
      replySourceComment.comment_post_ID = 0;
      replySourceComment.comment_author = '';
      replySourceComment.comment_author_email = '';
      replySourceComment.comment_author_url = '';
      replySourceComment.comment_date = '';
      replySourceComment.comment_content = '';
      replySourceComment.comment_approved = '';
      replySourceComment.comment_agent = '';
      replySourceComment.comment_type = '';
      replySourceComment.comment_parent = 0;
      replySourceComment.avatar = '';
      replySourceComment.replies = [];
      replySourceComment.user_id = 0;
    };

    return {
      state,
      post,
      postLoading,
      user,
      userLoading,
      loaded,
      relation,
      relationLoading,
      comments,
      replyClicked,
      replySourceComment,
      replyCancelClicked,
    };
  },
});
</script>

<template>
  <div v-if="state.notFound" class="component">
    <NotFound />
  </div>
  <div v-else>
    <div v-if="postLoading" class="component">
      <Spinner />
    </div>
    <div v-else>
      <Breadcrumb>
        <li>{{ post.post_title }}</li>
      </Breadcrumb>
      <section class="component">
        <h1 class="h1 title">{{ post.post_title }}</h1>
        <div id="entry-post" class="post">
          <p class="date">{{ post.post_date }}</p>
          <div class="contents-text" v-html="post.post_content"></div>
        </div>
        <div class="category">
          <router-link
            tag="p"
            :to="`/category/${category.slug}`"
            class="text"
            v-for="(category, i) in post.categories"
            :key="i"
          >
            {{ category.name }}
          </router-link>
        </div>
        <div class="tag">
          <router-link tag="p" :to="`/tag/${tag.slug}`" class="text" v-for="(tag, i) in post.tags" :key="i">
            {{ tag.name }}
          </router-link>
        </div>
        <div class="author-wrapper" v-if="!userLoading && post.post_type === 'post'">
          <hr />
          <Author v-if="user.ID" :user="user" />
        </div>
      </section>
      <section v-if="comments.length && post.post_type === 'post'" class="component">
        <h2 class="h2 title">この記事へのコメント</h2>
        <Comment :comments="comments" @reply="replyClicked" />
      </section>
      <section v-if="post.post_type === 'post'" id="form" class="component">
        <h2 v-if="replySourceComment.comment_ID" class="h2 title">
          <span> {{ `${replySourceComment.comment_author || '匿名'}` }}へ返信する </span>
          <button class="cancel-button" @click="replyCancelClicked">返信をキャンセル</button>
        </h2>
        <h2 class="h2 title" v-else>コメントする</h2>
        <CommentForm :replySourceComment="replySourceComment" :post="post" />
      </section>
    </div>
    <section v-if="!relationLoading && relation.length" class="component">
      <h2 class="h2 title">関連記事</h2>
      <ul class="ul">
        <li class="li" v-for="(r, i) in relation" :key="i">
          <router-link :to="`/${r.post_name}`">
            <Figure :post="r" />
          </router-link>
        </li>
      </ul>
    </section>
  </div>
</template>

<style lang="scss">
.component {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: $color-light;
  margin: 16px 0 0;

  .h1 {
    &.title {
      line-height: 1.3;
      letter-spacing: 0.02em;
      font-size: 40px;
      font-weight: bold;
      border-bottom: 2px solid $color-primary;
    }
  }

  .h2 {
    &.title {
      border-bottom: solid 1px #aaa;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 8px 0;
    font-weight: bold;
    margin: 0 0 10px 0;

    & > span {
      flex: 1;
    }

    & > .cancel-button {
      display: block;
      margin: 8px 0;
      border-radius: 3px;
      font-size: 13px;
      color: #fff;
      background: #ccc;
      padding: 4px 8px;
      cursor: pointer;
      outline: none;
      border: none;
    }
  }

  .category {
    display: flex;
    flex-wrap: wrap;

    .text {
      margin: 4px;
      padding: 2px 8px;
      box-sizing: border-box;
      width: fit-content;
      background-color: $color-primary;
      color: $color-text-dark;

      &:hover {
        background-color: $color-primary-light;
      }
    }
  }

  .tag {
    display: flex;
    flex-wrap: wrap;

    .text {
      margin: 4px;
      padding: 2px 8px;
      box-sizing: border-box;
      width: fit-content;
      background-color: $color-accent;
      color: $color-text-light;
      border-radius: 15px;
    }
  }

  .post {
    margin-bottom: 128px;

    .date {
      font-size: 12px;
      font-weight: bold;
      color: #aaa;
      margin-bottom: 15px;
      text-align: right;
    }

    figure {
      margin: 30px auto;
      text-align: center;
      max-width: 100% !important;
    }

    img {
      max-width: 100% !important;
      height: auto;
    }

    iframe {
      max-width: 100% !important;
    }

    .wp-video {
      width: 100% !important;
      max-width: 100% !important;

      video {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  .ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .li {
      margin: 8px 0;
      padding: 0;
      box-sizing: border-box;
      list-style: none;
    }
  }
}

@include mq(lg) {
  .component {
    .h1 {
      &.title {
        font-size: 25px;
        font-weight: bold;
        margin: 15px 0;
      }
    }
  }
}
</style>
