
import { IUser } from '@/models/user';

export default {
  props: {
    user: {
      type: Object as () => IUser,
      required: true,
    },
  },
};
